/* eslint-disable no-underscore-dangle */
import React from "react";
import Contact from "components/Contact";
import ServicesApi from "api/services";
import { Link } from "react-router-dom";
import NoList from "components/NoList";
import LabreportModal from "modules/consultation-list/modals/LabreportModal";
import { useState } from "react";

const Footer = ({ noLink }) => {
  const [labModal, setLabModal] = useState(false);

  let renderServices = [
    { title: "abcd", key: 1 },
    { title: "abcdef", key: 2 },
    { title: "abcde", key: 3 },
    { title: "abcdefgh", key: 4 },
    { title: "abcddd", key: 5 },
    { title: "abcddddd", key: 6 },
    { title: "abcddddd", key: 7 },
  ];
  renderServices = renderServices.map((i) => (
    <div key={i.key} className="no-link">
      {i.title}
    </div>
  ));

  if (!noLink) {
    const { data } = ServicesApi.readServices(true);
    renderServices = data ? (
      data.map((i) => (
        <Link key={i._id} to={`/services/${i.slug}`}>
          {i.title}
        </Link>
      ))
    ) : (
      <NoList />
    );
  }

  const handleOpen = () => {
    setLabModal(true);
  };

  return (
    <>
      <LabreportModal labModal={labModal} setLabModal={setLabModal} />
      <Contact />
      <footer>
        <div className="container footer-containers">
          <div className="row footer-outer-wrapper">
            <div className="col-lg-4">
              <h2 className="footer-head">Sabine Hospital</h2>
              <div className="footer-about">{renderServices}</div>
            </div>
            <div className="col-lg-4">
              <div className="footer-menu">
                <h3 className="footer-head">Quick Links</h3>
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/pages/medical-tourism">Medical Tourism</Link>
                  </li>
                  <li>
                    <Link to="/testimonials">Testimonials</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About Us</Link>
                  </li>
                  <li>
                    <Link to="/gallery">Gallery</Link>
                  </li>
                  <li>
                    <Link to="/services">Service</Link>
                  </li>
                  <li>
                    <Link to="/careers">Careers</Link>
                  </li>
                  <li>
                    <Link to="/doctors">Doctors</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                    <Link to="/blogs">{`What's new`}</Link>
                  </li>
                  <li>
                    {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                    <Link to="/csr-policy">CSR Policy</Link>
                  </li>
                  <li>
                    {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                    <Link to="pages/refund-and-return-policy">Refund and Return Policy</Link>
                  </li>
                  <li>
                    {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                    <Link to="/pages/terms-of-use">Terms of use</Link>
                  </li>
                  <li>
                    {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                    <Link to="/pages/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="footer-contact" style={{ padding: "10px" }}>
                <h4 className="footer-head">Contact</h4>
                <a href="tel:0485-2838100">
                <div className="footer-cont">
                  <img src="/img/phone-icon.svg"/>
                  <h4>0485-2838100</h4>
                  </div>
                  </a>
                  <a href="mailto:feedback@sabinehospital.com">
                  <div className="footer-address">
                  <img src="/img/mail-icon.svg"/>
                  <h4 style={{textTransform:"none"}}>feedback@sabinehospital.com</h4>
                  </div>
                  </a>
                  <div className="footer-addr">
                  <img src={`${process.env.REACT_APP_BASE_URL}/resources/img/a2gir-footer-map-icon.svg`}/>
                  <h4>Sabine Hospital & Research Centre Pvt. Ltd.Pezhakkappilly P.O, Muvattupuzha,Kerala, India 686673</h4>
                  </div>
                {/* <address>
                  
                  
                  <h5>
                    <img
                      className="lazy"
                      style={{ objectFit: "contain" }}
                      src={`${process.env.REACT_APP_BASE_URL}/resources/img/a2gir-footer-map-icon.svg`}
                      alt=""
                    />
                  </h5>
                  <p style={{ width: "100%", minWidth: "280px" }}>
                    Sabine Hospital & Research Centre Pvt. Ltd.
                    <br />
                    Pezhakkappilly P.O, Muvattupuzha,
                    <br />
                    Kerala, India 686673
                  </p>
                </address> */}
                <div className="footer-social-icons">
                <a
                    href="https://www.facebook.com/shrcmuvattupuzha"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src="/img/facebook.svg" />
                  </a>
                  <a
                    href="https://www.instagram.com/sabine_hospital/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src="/img/instagram.svg" />
                  </a>
                  <a
                    href="https://twitter.com/sabine_hospital"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src="/img/twitter.svg" />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCTP1ClAeQs4bzh_uIVo60SQ"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src="/img/linkedin.svg" />
                  </a>
                  <a
                    href="https://www.facebook.com/shrcmuvattupuzha"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img src="/img/telegram.svg" />
                  </a>

                </div>
                {/* <div className="footer-social">
                  <a
                    href="https://www.facebook.com/shrcmuvattupuzha"
                    rel="noopener noreferrer"
                    target="_blank"
                  > */}
                    {/* <i className="fab fa-facebook-f" /> */}
                    {/* <img src="/img/facebook.svg" />
                  </a>
                  <a
                    href="https://twitter.com/sabine_hospital"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="fab fa-twitter" />
                  </a>
                  <a
                    href="https://www.instagram.com/sabine_hospital/"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="fab fa-instagram" />
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCTP1ClAeQs4bzh_uIVo60SQ"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <i className="fab fa-youtube" />
                  </a>
                </div> */}
                {/* <li className="lab-report" onClick={handleOpen}>
                  <button
                    type="button"
                    className="footer-btn"
                  >
                    Lab Report
                  </button>
                </li>
                <Link to="/consultation" className="lab-reports">
                  <button
                    type="button"
                    className="footer-btn"
                  >
                    Book an Appointment
                  </button>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-6 developed-by">
                <span>
                  Copyright sabinehospitals.com © {new Date().getUTCFullYear()}. v1.1.8
                  All rights reserved | Developed By 
                </span>&nbsp;
                <span className="riolabz-title" onClick={()=>window.open('https://riolabz.com/', '_blank')}> Riolabz</span>
              </div>
              {/* <div className="col-lg-6 col-md-6">
                <h5 className="developedby">
                  Developed By{" "}
                  <a
                    href="http://riolabz.com"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Riolabz
                  </a>
                </h5>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

Footer.defaultProps = {
  noLink: false,
};

export default Footer;
